import React       from 'react';
import { graphql } from 'gatsby';

const SeikoBrandPageTemplate = props => <pre>{JSON.stringify(props, null, 2)}</pre>;

export default SeikoBrandPageTemplate;

export const query = graphql`
  query($slug: String!, $type: String!) {
    directusBrands(brand: {slug: {eq: $slug}, type: {type: {eq: $type}}}) {
      ...BrandsCore
    }
    directusBrandTypes(type: {eq: $type}) {
      type
      translations {
        display_name
        language
      }
    }
      directusProject(id: {ne: "dummy"}) {
          custom_values {
              has_seiko_iframe
          }
      }
  }
`;